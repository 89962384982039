import { Box, Container, Flex, Heading, Link, Text } from "@chakra-ui/react";
import Generator from "../components/Generator";

function HomePage() {
  return (
    <>
      <Box as="main" py={10}>
        <Container maxW="960px">
          <Generator />
        </Container>
      </Box>
      <Box as="section" bg="gray.200" p={10}>
        <Text textAlign="center">
          <Link href="https://upi.ac" target="_blank">
            UPI.ac
          </Link>{" "}
        </Text>
      </Box>
    </>
  );
}

export default HomePage;
